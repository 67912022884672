<template>
  <PageContent class="compte">
    <template #content>
      <Account
        :user="user"
        :organisme-actuel="organismeActuel"
        :can-change-password="!isGE && hasPerm('can_change_password')"
        @validate-img="!isImpersonating ? changeAvatar($event) : null"
        @submit-user="!isImpersonating ? submitUser($event) : null"
      >
        <template #block-right>
          <div
            v-if="organismeActuel && organismeActuel.nom_complet"
            class="etablissement flex-vcenter"
          >
            <IconLycee size="40" />
            <p class="text-regular">
              {{ organismeActuel.nom_complet }}
            </p>
          </div>
          <div class="input-role">
            <InputClassic
              v-model="role"
              :label="$t('compte.role-sur-site')"
              status="locked"
            />
          </div>

          <div class="toggle-container">
            <ButtonToggle
              name="creation_devis"
              :value="hasPerm('can_liste_to_devis')"
              :label="$t('compte.creation-de-devis')"
              type="switch"
              disabled
            />
            <ButtonToggle
              v-if="!isHorsMarche && !hasPerm('can_devis_to_cmd')"
              name="soumission_region"
              :value="hasPerm('can_transmettre_devis')"
              :label="typeMarcheTraduction().compteSoumissionARegion"
              type="switch"
              disabled
            />
            <!-- TODO: changer les perms ? -->
            <!-- Retirer le false une fois qu'on aura la gestion des établissements -->
            <ButtonToggle
              v-if="hasPerm('can_transmettre_devis') && false"
              name="gestion_etablissement"
              :value="hasPerm('can_transmettre_devis')"
              :label="$t('compte.gestion-dun-etablissement')"
              type="switch"
              disabled
            />
            <!-- TODO: Ajouter le lien une fois qu'on aura la gestion d'un établissement -->
            <!-- ButtonClassic avec balise-type="router-link"... -->
            <ButtonToggle
              name="commande_lde"
              :value="hasPerm('can_devis_to_cmd')"
              :label="$t('commande.commande-a-lde')"
              type="switch"
              disabled
            />
          </div>
          <!-- TODO: À revoir quand on aura plus d'informations ? -->
          <ButtonClassic
            v-if="false"
            icon="right"
            :label="$t('compte.exprimer-un-desiderata')"
            variant="ghost"
            color="primary"
            disabled
          >
            <template #right-icon>
              <UilCommentDots />
            </template>
          </ButtonClassic>
          <div class="illustration flex-vcenter">
            <img
              :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_website_maintainence.svg')"
              :alt="$t('general.alt-illustration-pour-parametrage')"
            />
          </div>
        </template>
      </Account>
    </template>
  </PageContent>
</template>

<script>
import {
  Account,
  InputClassic,
  PageContent,
  ButtonToggle,
} from "@lde/core_lde_vue";

import IconLycee from "@/components/icons/IconLycee.vue";
import { UilCommentDots } from "@iconscout/vue-unicons";

import Api from "@/modules/axios";

import typeMarcheTraduction from "@/modules/trad_utils";

import { mapGetters } from "vuex";

export default {
  name: "Compte",
  components: {
    PageContent,
    Account,
    InputClassic,
    ButtonToggle,
    IconLycee,
    UilCommentDots,
  },
  computed: {
    ...mapGetters(["organismeActuel", "user", "isHorsMarche", "hasPerm", "isGE"]),
    role() {
      switch (this.user.role) {
        case "Opérateur LDE":
          return this.$t("role.operateur-lde");
        case "COR_NUM_LIB":
          return this.$t("role.cor-num-lib");
        case "Donneur d'ordre":
          return this.$t("role.donneur-dordre");
        case "Valideur":
        case "Valideur hors-marché":
          return this.$t("role.valideur");
        case "Prescripteur":
          return this.$t("role.prescripteur");
        default:
          return this.$t("role.consultant");
      }
    },
  },
  methods: {
    typeMarcheTraduction,
    /**
     * Enregistre les données de l'utilisateur.
     * @param {Object} user Nouvelles informations de l'utilisateur.
     */
    submitUser(user) {
      this.$store.commit("setUser", user);
      this.$toast.success({ title: this.$t("toast.compte-mis-a-jour") });
    },
    /**
     * Lorsqu'on confirme le changement d'avatar.
     * @param {Object} imgFromModal Image sélectionnée dans la modale (proposée ou upload).
     */
    changeAvatar(imgFromModal) {
      let formdata = null;

      if (imgFromModal.checkedImg) {
        formdata = { avatar_from_library: imgFromModal.checkedImg };
      } else if (imgFromModal.uploadedFile) {
        formdata = new FormData();
        formdata.append("avatar", imgFromModal.uploadedFile);
      }

      if (formdata) {
        Api().post(`/utilisateur/${this.user.id}/upload_avatar/`, formdata)
          .then((res) => {
            this.$store.commit("setAvatar", res.data);
            this.$toast.success({ title: this.$t("modal-change.avatar-modifie-avec-succes") });
            this.$modal.hide("modal_change_image");
          })
          .catch((error) => {
            this.$toast.error({ title: error.response?.data?.avatar[0] || error });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_compte.scss";
</style>
